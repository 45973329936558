    @import './css/animate.css';
    @import './css/forms.css';
    @import './css/navigation.css';
    @import './css/header.css';
    @import './css/buttons.css';
    @import './css/panels.css';
    @import "~@ng-select/ng-select/themes/default.theme.css";
    
    /* custom */
    
    html {
        overflow-y: scroll;
    }
    /* General settings start */
    
    @media (min-width: 1170px) {
        .container {
            width: 1000px;
        }
        .search-bar {
            width: 193px;
        }
        .topright {
            margin-top: 20px;
        }
        .nav-button>li {
            margin-left: 4px;
        }
    }
    
    td,
    th {
        font-size: 12px;
        font-weight: normal;
    }
    
    .gray-box {
        background: gray;
        color: #fff;
    }
    
    .hidden {
        visibility: hidden;
    }
    /* Margin settings start */
    
    .top-bottom-margin {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .bottomMargin {
        margin-bottom: 10px !important;
    }
    
    .topMargin {
        margin-top: 20px;
    }
    
    .mr-5 {
        margin-right: 5px;
    }
    
    .btn-green {
        background: #c1ffc169;
    }
    
    .mb-5 {
        margin-bottom: 5px;
    }
    /* Margin settings end */
    /* Profile name in the navigation bar Start ex. (test@test.com) */
    
    .profile-name {
        color: gold !important;
    }
    /* General settings end */
    /* Telerik report fix */
    
    .trv-menu-large {
        display: inline-flex !important;
    }
    
    #reportViewer1 *,
    #reportViewer1 *:before,
    #reportViewer1 *:after {
        -webkit-box-sizing: content-box !important;
        -moz-box-sizing: content-box !important;
        box-sizing: content-box !important;
    }
    /* Form validation */
    
    .required::before {
        color: #dd3030;
        content: '*';
    }
    
    .mt-40 {
        margin-top: 40px;
    }
    
    .mt-20 {
        margin-top: 20px;
    }
    
    .mb-40 {
        margin-bottom: 40px;
    }
    
    .mb-20 {
        margin-bottom: 20px;
    }
    /* new design css */
    
    .card-form {
        width: 100%;
        border-radius: 5px;
        background: white;
        box-shadow: 0 27px 55px 0 rgba(0, 0, 0, 0.3), 0 17px 17px 0 rgba(0, 0, 0, 0.15);
    }
    
    .card-form .underline {
        text-decoration: underline;
    }
    
    .card-form .form-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 15px;
        color: white;
        font-weight: bold;
        background: #524343;
        border-radius: 0px 0px 0 0;
    }
    
    .card-form .form-body {
        padding: 10px;
    }
    
    .required-star {
        color: red;
    }
    
    .form-control {
        border-radius: 0;
    }
    
    .btn-flat {
        border-radius: 0;
    }
    
    .form-horizontal .control-label {
        text-align: right;
        padding-top: 7px !important;
        margin-bottom: 0;
    }
    
    .control-label {
        text-align: right;
        padding-top: 7px !important;
        margin-bottom: 0;
    }
    
    @media only screen and (max-width: 716px) {
        .card-form .form-horizontal .form-footer button {
            margin-bottom: 5px;
        }
        .card-form .form-horizontal .footer-info .text-right {
            text-align: left;
        }
    }
    
    .card-form .form-body .row {
        display: flex;
        justify-content: space-around;
        padding: 5px 15px;
    }
    /*     
    .card-form .form-body .row {
        display: flex;
        justify-content: space-around;
        padding: 5px 15px;
    }
    
    .card-form .form-body .row label {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        margin: 0 15px;
        padding: 5px 0px;
        font-size: 15px;
    }
    
    .form-group {
        width: 50%;
        margin: 0;
    }
    
    .card-form .form-body .row input[type="text"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 85%;
        margin: 0 15px;
        height: 35px;
        padding: 5px 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background: #e8ebed;
        color: #000000;
        font-size: 14px;
    }
    
    .card-form .form-body .row .selectOption {
        -moz-appearance: none;
        width: 85%;
        margin: 0 15px;
        padding: 5px 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background: #e8ebed;
        color: #576366;
        font-size: 14px !important;
        height: 35px !important;
    }
    
    .card-form .form-body .row textarea[type="text"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 85%;
        margin: 0 15px;
        height: 50px;
        padding: 5px 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background: #e8ebed;
        color: #000000;
        font-size: 14px;
    }
    
    .card-form .form-body .row input[type="checkbox"] {
        width: 5%;
        margin: 0 15px;
        height: 35px;
        padding: 5px 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background: #e8ebed;
        color: #576366;
        font-size: 14px;
    }
    
    .card-form .form-body .row input[type="number"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 85%;
        margin: 0 15px;
        height: 35px;
        padding: 5px 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background: #e8ebed;
        color: #000000;
        font-size: 14px;
    }
    
    
    @media only screen and (max-width: 716px) {
        .card-form .form-horizontal .form-footer button {
            margin-bottom: 5px;
        }
        .card-form .form-horizontal .footer-info .text-right {
            text-align: left;
        }
        .card-form .form-body .row input[type="text"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 85%;
            margin: 0 15px;
            height: 26px;
            padding: 5px 9px;
            border-radius: 5px;
            outline: none;
            border: none;
            background: #e8ebed;
            color: #000000;
            font-size: 13px;
        }
        .card-form .form-body .row input[type="number"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 85%;
            margin: 0 15px;
            height: 26px;
            padding: 5px 9px;
            border-radius: 5px;
            outline: none;
            border: none;
            background: #e8ebed;
            color: #000000;
            font-size: 13px;
        }
        .card-form .form-body .row textarea[type="text"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 85%;
            margin: 0 15px;
            height: 45px;
            padding: 5px 9px;
            border-radius: 5px;
            outline: none;
            border: none;
            background: #e8ebed;
            color: #000000;
            font-size: 13px;
        }
        .card-form .form-body .row label {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            margin: 0 15px;
            padding: 5px 0px;
            font-size: 13px;
        }
        .card-form .form-body .row .selectOption {
            -moz-appearance: none;
            width: 85%;
            margin: 0 15px;
            padding: 5px 8px;
            border-radius: 5px;
            outline: none;
            border: none;
            background: #e8ebed;
            color: #576366;
            font-size: 13px !important;
            height: 26px !important;
        }
        .card-form .form-body .row input[type="checkbox"] {
            width: 17%;
            margin: -4px 15px;
            height: 34px;
            padding: 5px 15px;
            border-radius: 5px;
            outline: none;
            border: none;
            background: #e8ebed;
            color: #576366;
            font-size: 13px;
        }
    } */
    
    .form-my {
        display: flex;
    }
    
    .alert {
        padding: 5px;
    }
    
    .footer-info {
        border-top: 2px #e8ebed solid;
        margin: 4px 0px;
    }
    
    .form-footer {
        width: 100%;
        overflow: hidden;
    }
    
    .buttons-row button {
        margin-bottom: 15px;
    }

    .button-controls button {
        width: 150px; 
        margin-right:10px;
    }
    
    .nopadding {
        padding-left: 0px;
        padding-right: 0px;
    }
    /* new form */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (max-width:420px) {
	.table-container {
		overflow-y: scroll;
	}

	.btn-list-sml {
		background-color: #fff !important;
		color: black !important;
		width: 100% !important;
	}

	.div-list-sml {
		margin-top: 10px;
	}

	.remove-float-sml {
		float: none !important;
	}

	.btn-edit-sml {
		width: 100% !important;
		margin-top: 10px;
	}
    .remove-width-sml{
       width: auto !important;
    }
}
