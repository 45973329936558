/* Panels Start */

.panel-primary {
    border-color: #000 !important;
}

.panel-primary>.panel-heading {
    background: #444040;
    color: #fff;
    border-color: #e4ccc7 !important;
}

.panel-primary-inverted>.panel-heading {
    background: #00a252;
    color: black;
    border-color: #e4ccc7;
    font-size: 12px;
}


/* Panels End */