/* Navigation bar Start */

.navbar{
    margin-bottom: 0;
}

.navbar-default {
    background-color: #00569d;
    border-color: #e4ccc7;
    color: #fff;
}

.navbar-default .navbar-nav > li > a:not(.profile-name), .navbar-default .navbar-brand {
    color: #fff ;
    cursor: pointer;
}

.navbar-default .navbar-nav > li > a.active {
    color: #ffa !important;
    cursor: pointer;
}

.navbar-default .navbar-nav > li > a.admin:hover:not(.profile-name) {
    color: #fff !important;
    background: #00569d !important;
    cursor: pointer;
}

.navbar-default .navbar-nav > li.admin > a.admin{
    color: #fff !important;
    background:#00569d !important;
}

.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color:#00569d;
}

.nav-button > li{
    border-radius: 14px 14px 0px 0px;
    margin-top: 10px;
    color: white;
    background: gray;
    text-align: center;
    display: block;
    height: 30px;
    width: 130px;
    border: 1px solid #00569d;
    font-size: 13px;
}

.nav-button > li > a {
    font-style: italic;
    
    text-align: center;
    vertical-align: middle;

    position: relative;
    top: 50%;
    transform: translateY(-50%);

    color: white;
}

/* Navigation bar End */

/* Tabs Start */
.nav-tabs {
    border-bottom: 2px solid #DDD;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    border-width: 0;
}

.nav-tabs > li > a {
    border: none;
    color: #666;
}

.nav-tabs > li.active > a,
.nav-tabs > li > a:hover {
    border: none;
    color: #4285F4 !important;
    background: transparent;
}

.nav-tabs > li > a::after {
    content: "";
    background: #4285F4;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.nav-tabs > li.active > a::after,
.nav-tabs > li:hover > a::after {
    transform: scale(1);
}

.tab-nav > li > a::after {
    background: #21527d none repeat scroll 0% 0%;
    color: #fff;
}

.tab-pane {
    padding: 15px 0;
}

.tab-content {
    padding: 20px
}

.card {
    background: #FFF none repeat scroll 0% 0%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
}

/* Tabs End */

/* Hide image column when displaying on mobile devices (Catalog Links page)*/
@media screen and (min-width: 0px) and (max-width: 767px) {
  .hide-on-mobile 
  { 
      display: none; 
  }

  .navbar-default {
    background-color: transparent;
    border:none;
    color: #fff;
}

.nav-button > li{
    border-radius: 14px !important;
}

.mobile-button {
    width: 49% !important;
    margin-bottom: 4px;
    background: gray;
    color: white;
    border-radius: 14px !important;
    outline: none !important;
}

.mobile-button-icon {
    width: 24% !important;
    margin-bottom: 4px;
    background: gray;
    color: white;
    border-radius: 14px !important;
    outline: none !important;
}

.navbar-default .navbar-nav > li > a.admin:hover:not(.profile-name) {
    color: #fff !important;
    background: silver !important;
    cursor: pointer;
}

.navbar-default .navbar-nav > li.admin > a.admin{
    color: #fff !important;
    background:silver !important;
}

 .btnBlock {
    display: block !important;
    width: 100% !important;
}

}

.headerTip {
    color: #00569d;
    font-weight: bold;
}