/* Kendo UI styling start */
span.k-icon.k-clear-value.k-i-close{
    display: none !important;
}

span.k-icon.k-i-close {
    position: relative !important;
    left: 20px !important;
}

.k-combobox{
    width: 100% !important;
}

.k-dialog-titlebar {
    background-color: rgb(11, 24, 85);
}
/* Kendo UI styling end */