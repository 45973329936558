@import 'kendo-ui.css';

/* Bootstrap customization start */
.control-label {
    padding-top: 0 !important;
}

.form-control-static {
    word-wrap: break-word;
}

.form-horizontal {
    text-align: left !important;
}

.form-horizontal .control-label {
    text-align: left;
}

input.form-control:not(.searchField){
    height: 28px;
    font-size: 12px;
}
/* Bootstrap customization end */

/* General form objects start */
label {
    font-weight: normal;
    font-size: 12px;
}

select, dropdownlist {
    height: 28px !important;
    font-size: 12px !important;
}

.inline-label {
    margin-right: 10px !important;
    display: block;
}

.block-element {
    display: block !important;
    width: 100% !important;
    /* margin: 4px !important; */
}

.inline-input {
    margin-right: 10px !important;
}

.required::before {
    content: '*';
    color: crimson;
    font-size: 15px;
}

.form-group-checkbox {
    margin-bottom: 2px;
}
/* General form objects end */