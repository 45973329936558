/* Buttons Start */

.btn-creation {
    color: #A71F00;
    background-color: #fff;
    border-color: #fff;
}

.btn-deletion {
    color: #A71F00;
    background-color: #ddd;
    border-color: #ddd;
    margin-left: 6px;
}

.btn-edit {
    color: #fff;
    background-color: #A71F00;
    border-color: #A71F00;
}

.btn-edit:hover, .btn-edit:focus, .btn-primary:hover, .btn-primary:focus {
    color: #ffa;
    text-decoration: none;
}

.btn1-5 {
    width: 25%;
    margin-bottom: 5px;
    white-space: normal;
    height: 62px;
    float: left;
}

/* Buttons End */