/* Header start */
.image-holder {
    padding: 7px 2%;
}

.logo > img {
    max-width: 100%;
    max-height: 100%;
}

.login > a {
    color: gray;
    text-decoration: none;
}

.topright > .row > a,
.topright > .row > p {
    font-size: 20px;
}

.header-page {
    text-transform: uppercase;
}

/* Header end */